<template>
  <Page>
    <ViewTitle :title="labels.Proceedings" />
    <h2 class="mb-2">{{ labels.Requests }}</h2>
    <h3 class="float-right" v-for="item in this.userCollection" :key="'uc_' + item.ID">{{ item.RequestedBy }}</h3>

    <Toggle mode="head" group="proceeding" :btnlabel="labels.RequestDetails" css="table">
      <template v-slot:title>
        <div class="container">
          <div class="row">
            <div class="col">{{ labels.Request }}</div>
            <div class="col">{{ labels.RequestDate }}</div>
            <div class="col">{{ labels.RequestState }}</div>
          </div>
        </div>
      </template>     
    </Toggle>

    <Toggle v-for="item in this.listCollection" :key="'lc' + item.ID" mode="line" :id="'id_' + item.ID" group="proceeding" :btnlabel="labels.RequestDetails" css="table">
      <template v-slot:title>
        <div class="container">
          <div class="row">
            <div class="col">{{ item.Name }}</div>
            <div class="col">{{ get_formatted_date(item.Requested,this.$root.config.localization.language_short_date_format) }}</div>
            <div class="col" v-if="item.State == 'InProgress'"><b-icon icon="hourglass-split"></b-icon>&nbsp;{{ labels.RequestStateInProgress }}</div>
            <div class="col" v-else><b-icon icon="check-circle"></b-icon>&nbsp;{{ labels.RequestStateCompleted }}</div>                          
          </div>
        </div>       
      </template>
      <template v-slot:content>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="container">
                <div class="row"><div class="col vcs-name p-2">{{ labels.RequestRequestedBy }}</div><div class="col vcs-value p-2">{{ item.RequestedBy }}</div></div>
                <div class="row"><div class="col vcs-name p-2">{{ labels.RequestResponsibleAuthority }}</div><div class="col vcs-value p-2">
                  <div v-if="item.Authority != ''">{{ item.Authority }}</div>
                  <div v-if="item.AuthorityAddress != ''">{{ item.AuthorityAddress }}</div>
                  <div v-if="item.AuthorityPhone != ''">{{ item.AuthorityPhone }}</div>
                  <div v-if="item.AuthorityEMail != ''"><a v-bind:href="'mailto:' + item.AuthorityEMail">{{ item.AuthorityEMail }}</a></div>
                </div></div>
              </div>                       
            </div>
            <div class="col">
              <div class="container">
                <div class="row"><div class="col vcs-name p-2">{{ labels.RequestDate }}</div><div class="col vcs-value p-2">{{ get_formatted_date(item.Requested,this.$root.config.localization.language_short_date_format) }}</div></div>
                <div class="row"><div class="col vcs-name p-2">{{ labels.RequestState }}</div>
                  <div class="col vcs-value p-2" v-if="item.State == 'InProgress'"><b-icon icon="hourglass-split"></b-icon>&nbsp;{{ labels.RequestStateInProgress }}</div>
                  <div class="col vcs-value p-2" v-else><b-icon icon="check-circle"></b-icon>&nbsp;{{ labels.RequestStateCompleted }}</div>                        
                </div>
                <div class="row"><div class="col vcs-name p-2">{{ labels.RequestStateDate }}</div><div class="col vcs-value p-2">{{ get_formatted_date(item.Updated,this.$root.config.localization.language_short_date_format) }}</div></div>                  
              </div>                
            </div>
          </div>
        </div>       
      </template>        
    </Toggle>

  </Page>
</template>

<script>
import Page from '@/structure/Page.vue'
import Toggle from '@/structure/Toggle.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'

export default {
  mixins: [vcm,vvm],
  name: 'Proceedings',
  components: {
    Page, ViewTitle, Toggle
  },
  data() {
    return {      
      listCollection: [],
      userCollection: [],
      labels: {
        Proceedings: this.get_scope_translation('Proceedings','label'),
        Requests: this.get_scope_translation('Requests','label'),
        Request: this.get_scope_translation('Request','label'),
        RequestDate: this.get_scope_translation('RequestDate','label'),
        RequestState: this.get_scope_translation('RequestState','label'),
        RequestDetails: this.get_scope_translation('RequestDetails','label'),
        RequestRequestedBy: this.get_scope_translation('RequestRequestedBy','label'),
        RequestResponsibleAuthority: this.get_scope_translation('RequestResponsibleAuthority','label'),
        RequestStateDate: this.get_scope_translation('RequestStateDate','label'),
        RequestStateInProgress: this.get_scope_translation('RequestStateInProgress','label'),
        RequestStateCompleted: this.get_scope_translation('RequestStateCompleted','label'),
      }
    };
  },
  created: function () {
    // Call Proceedings //
    this.$root.data_access.setIdentifier("list.proceedings");
    this.$root.data_access.setReceiver("view", this);
    this.$root.data_access.call("proceedings");
  },
  mounted: function () {
  },
  computed: {   
   
  },
  methods: {
    receive(dataObject) {
      if (dataObject) {
        switch (dataObject.identifier) {
          case "list.proceedings": // Receive Proceedings //
            this.listCollection = dataObject.data;
            
            var scopeThis = this
            this.listCollection.forEach(function(item){
              var checkIndex = scopeThis.userCollection.findIndex(x => x.RequestedBy == item.RequestedBy);
              if (checkIndex <= -1) {
                scopeThis.userCollection.push({ ID: item.ID, RequestedBy: item.RequestedBy});
              }
            });

            break;
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/_var.scss";
 
.vcs-name { font-size: 0.875rem; line-height: 1.25rem; color: #121212; font-weight: 700; }
.vcs-value { font-size: 0.875rem; line-height: 1.25rem; color: #000000; font-weight: 400; }
.vcs-value a { color: $highlightFontColor; }
.vcs-value a:hover { color: $highlightFontColor; border-bottom: 1px solid transparent; }
.vcs-value a:focus { outline: none; box-shadow: none; border-bottom: 1px solid $focusHighlightColor; }


h3 { width: 100%; text-align: right; margin-top: -2rem; font-size: 1rem; line-height: 1rem; padding-right: 0.3rem; }


</style>



